<script setup>
  const props = defineProps({
    image: {
      type: Object,
      default: {
        default: null,
        defaultContentType: null,
        webp: null,
        small: null,
        meta: {
          title: ''
        }
      }
    },
    size: {
      type: String,
      default: 'default'
    }
  });

  let filenameAlt = '';
  if (props.image.default) {
    filenameAlt = props.image.default.split('/');
    filenameAlt = filenameAlt[filenameAlt.length - 1].split('.')[0];
    filenameAlt = filenameAlt.replace(/[-_]/g, ' ');
  }
</script>

<template>
  <picture class="content" v-if="image !== null">
    <source v-if="image.webp !== null" media="(max-width: 500px)" :srcset="image.webp" type="image/webp">
    <source v-if="props.size !== 'default'" :srcset="image.small" type="image/png">
    <source v-else-if="image.default !== null" :srcset="image.default" type="image/png">
    <img
        v-if="props.size === 'small'"
        fetchpriority="high"
        decoding="sync"
        class="img-full-w"
        :src="image.small"
        :alt="image.meta?.title ?? filenameAlt"
        :title="image.meta?.title ?? filenameAlt"
    >
    <img
        v-else-if="image.default !== null"
        fetchpriority="high"
        decoding="sync"
        class="img-full-w"
        :src="image.default"
        :alt="image.meta?.title ?? filenameAlt"
        :title="image.meta?.title ?? filenameAlt"
    >
  </picture>
</template>

<style scoped lang="scss">

</style>
